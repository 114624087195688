/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserAccountID, UserOrganizationID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserMember",
  "scope": "User",
  "table": "user",
  "type": "User:Member"
})
export class UserMember extends HewSyncType {
	public static readonly Type = UserMember as typeof HewSyncType;
	public static override readonly type = "UserMember";
	public override readonly type = UserMember.type;

	@HewSync.Field({ })
	public readonly account: UserAccountID;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly settings: any;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserMember, UserMember.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserMember({
				...UserMember.defaults(),
				account: ids.account,
				organization: ids.organization,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserMember, UserMember.IDs>) {
		return HewSync.get<UserMember>(UserMember.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserMember, UserMember.IDs>>) {
		let list = new HewSyncList<UserMember>(UserMember.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserMember, UserMember.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserMember>(UserMember.Type, inputs);
	}

	public static batchGet(ids: Pick<UserMember, UserMember.IDs>[]) {
		return HewSync.batchGet<UserMember>(UserMember.Type, ids);
	}

	public static attach(values: UserMember.Create) {
		return HewSync.create<UserMember>(UserMember.Type, "attach", values);
	}

	public static detach(ids: Pick<UserMember, UserMember.IDs>, target?: UserMember) {
		return HewSync.request<UserMember>(UserMember.Type, "detach", ids, target);
	}

	public static update(ids: Pick<UserMember, UserMember.IDs>, values: Partial<Pick<UserMember, UserMember.Modifiable>>, target?: UserMember) {
		return HewSync.request<UserMember>(UserMember.Type, "update", {...values, ...ids}, target);
	}

	/********************************************/

	public detach() {
		return UserMember.detach(this.ids, this);
	}

	public update(values: Partial<Pick<UserMember, UserMember.Modifiable>>) {
		return UserMember.update(this.ids, values, this);
	}

	/********************************************/

	private static cache = new Map<string, UserMember>();

	public get ids() { return { account: this.account, organization: this.organization }; }

	public static getKey(ids: Pick<UserMember, UserMember.IDs>) { return ids.account.toString() + "/" + ids.organization.toString(); }

	public get key() { return this.account.toString() + "/" + this.organization.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).account = data.account;
		(this as any).organization = data.organization;

		this.apply({ ...UserMember.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserMember, UserMember.Fields>>) {
		(this as any).name = data.name;
		(this as any).settings = data.settings;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserMember;
		(values as any).name = "";
		(values as any).settings = {};
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserMember, UserMember.IDs | UserMember.Fields> {
		let output: PartialProps<UserMember, UserMember.IDs | UserMember.Fields> = {};
		if (data.account !== undefined) {
			output.account = new UserAccountID(data.account);
		}
		if (data.organization !== undefined) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.name !== undefined) {
			output.name = data.name;
		}
		if (data.settings !== undefined) {
			output.settings = data.settings;
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			account: this.account,
			organization: this.organization,
			name: this.name,
			settings: this.settings,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["account", "organization"] as const;
	public readonly fieldIDs = UserMember.fieldIDs;
	public static readonly fields = ["name", "settings", "createdAt", "updatedAt"] as const;
	public readonly fields = UserMember.fields;
}

export namespace UserMember {
	export type IDs = "account" | "organization";
	export type Fields = "name" | "settings" | "createdAt" | "updatedAt";
	export type Modifiable = "name" | "settings";
	export type Create = {
		account: UserAccountID;
		organization: UserOrganizationID;
		name: string;
		settings?: any;
	}

}
