/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserAssetID, UserOrganizationID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserAsset",
  "scope": "User",
  "table": "user",
  "type": "User:Asset"
})
export class UserAsset extends HewSyncType {
	public static readonly Type = UserAsset as typeof HewSyncType;
	public static override readonly type = "UserAsset";
	public override readonly type = UserAsset.type;

	@HewSync.Field({ })
	public readonly asset: UserAssetID;

	@HewSync.Field({ })
	public readonly kind: string;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly fileType: string;

	@HewSync.Field({ })
	public readonly metadata: any;

	@HewSync.Field({ })
	public readonly size: string;

	@HewSync.Field({ })
	public readonly status: string;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserAsset, UserAsset.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserAsset({
				...UserAsset.defaults(),
				asset: ids.asset,
				kind: ids.kind,
				organization: ids.organization,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserAsset, UserAsset.IDs>) {
		return HewSync.get<UserAsset>(UserAsset.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserAsset, UserAsset.IDs>>) {
		let list = new HewSyncList<UserAsset>(UserAsset.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserAsset, UserAsset.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserAsset>(UserAsset.Type, inputs);
	}

	public static batchGet(ids: Pick<UserAsset, UserAsset.IDs>[]) {
		return HewSync.batchGet<UserAsset>(UserAsset.Type, ids);
	}

	public static create(values: UserAsset.Create) {
		return HewSync.create<UserAsset>(UserAsset.Type, "create", values);
	}

	public static update(ids: Pick<UserAsset, UserAsset.IDs>, values: Partial<Pick<UserAsset, UserAsset.Modifiable>>, target?: UserAsset) {
		return HewSync.request<UserAsset>(UserAsset.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: Pick<UserAsset, UserAsset.IDs>, target?: UserAsset) {
		return HewSync.request<UserAsset>(UserAsset.Type, "remove", ids, target);
	}

	// fileUpload(/*...*/) {} 

	/********************************************/

	public update(values: Partial<Pick<UserAsset, UserAsset.Modifiable>>) {
		return UserAsset.update(this.ids, values, this);
	}

	public remove() {
		return UserAsset.remove(this.ids, this);
	}

	// fileUpload(/*...*/) {} 

	/********************************************/

	private static cache = new Map<string, UserAsset>();

	public get ids() { return { organization: this.organization, kind: this.kind, asset: this.asset }; }

	public static getKey(ids: Pick<UserAsset, UserAsset.IDs>) { return ids.organization.toString() + "/" + ids.kind.toString() + "/" + ids.asset.toString(); }

	public get key() { return this.organization.toString() + "/" + this.kind.toString() + "/" + this.asset.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).asset = data.asset;
		(this as any).kind = data.kind;
		(this as any).organization = data.organization;

		this.apply({ ...UserAsset.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserAsset, UserAsset.Fields>>) {
		(this as any).fileType = data.fileType;
		(this as any).metadata = data.metadata;
		(this as any).size = data.size;
		(this as any).status = data.status;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserAsset;
		(values as any).fileType = "";
		(values as any).metadata = {};
		(values as any).size = "";
		(values as any).status = "pending";
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserAsset, UserAsset.IDs | UserAsset.Fields> {
		let output: PartialProps<UserAsset, UserAsset.IDs | UserAsset.Fields> = {};
		if (data.asset !== undefined) {
			output.asset = new UserAssetID(data.asset);
		}
		if (data.kind !== undefined) {
			output.kind = data.kind;
		}
		if (data.organization !== undefined) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.fileType !== undefined) {
			output.fileType = data.fileType;
		}
		if (data.metadata !== undefined) {
			output.metadata = data.metadata;
		}
		if (data.size !== undefined) {
			output.size = data.size;
		}
		if (data.status !== undefined) {
			output.status = data.status;
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			asset: this.asset,
			kind: this.kind,
			organization: this.organization,
			fileType: this.fileType,
			metadata: this.metadata,
			size: this.size,
			status: this.status,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "kind", "asset"] as const;
	public readonly fieldIDs = UserAsset.fieldIDs;
	public static readonly fields = ["fileType", "metadata", "size", "status", "createdAt", "updatedAt"] as const;
	public readonly fields = UserAsset.fields;
}

export namespace UserAsset {
	export type IDs = "organization" | "kind" | "asset";
	export type Fields = "fileType" | "metadata" | "size" | "status" | "createdAt" | "updatedAt";
	export type Modifiable = "fileType" | "metadata" | "size" | "status";
	export type Create = {
		kind: string;
		organization: UserOrganizationID;
		fileType?: string;
		metadata?: any;
		size?: string;
		status?: string;
	}

}
